import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, ColorModeScript, extendTheme } from '@chakra-ui/react'
import App from './App';


const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}

const lightColors = {
  brand: {
    10:'#fff',
    50: "#ecefff",
    100: "#cbceeb",
    200: "#a9aed6",
    300: "#888ec2",
    400: "#666ea8",
    500: "#545454",
    600: "#3b3c6e",
    700: "#292a52",
    800: "#181a35",
    900: "#080819",
    1000:'linear-gradient(85deg, rgba(139, 4, 4, 0.86) 14.75%, rgba(26, 32 , 44, 1) 45.28%, rgba(199,104, 16, 1) 78.56%)',
    1100:'#A9A5A5',
    1200:''
  },
}

const darkColors = {
  brand: {
    50: "#1a202c",
    100: "#2d3748",
    200: "#4a5568",
    300: "#718096",
    400: "#a0aec0",
    500: "#cbd5e0",
    600: "#e2e8f0",
    700: "#edf2f7",
    800: "#f7fafc",
    900: "#ffffff",
  },
}

const fonts = {
  body: "'Poppins', sans-serif",
  heading: "'Poppins', sans-serif",
}

const theme = extendTheme({
  config,
  colors: {
    light: lightColors,
    dark: darkColors,
  },
  fonts
})


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);



root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <App />
    </ChakraProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
