import axios from 'axios';


const UseAxios =()=> {

    const instance = axios.create({
        
        baseURL: `${process.env['REACT_APP_BASE_URL']}`,
        timeout: 20000,
        headers: {'Authorization': 'Bearer '+ process.env['REACT_APP_BEARER_TOKEN']}
      });

      return instance;
}

export default UseAxios;

