

import {Dispatch, SetStateAction, useEffect } from 'react'
import { artistMatchType, existingMatch as existingMatchType } from '../App'
import { useNavigate } from 'react-router-dom'
import UseAxios from './useAxios'

export const useIsMatched = ({existingMatch,matchingId,setArtistMatch}:{
    existingMatch?:existingMatchType,
    matchingId?:string,
    setArtistMatch?: Dispatch<SetStateAction<artistMatchType>>
})=>{
    
    const navigate = useNavigate();

    const axios = UseAxios()

    useEffect(()=>{
        (
            async()=>{
                let shouldRender = false;
                try{
                    if(matchingId){
                        try{
                            let {data} = await axios.get(`/get-result/${matchingId}`);


                            if(data?.message ==='Successfully returned data'){
                                shouldRender=true;
                                setArtistMatch && setArtistMatch(data?.data as existingMatchType  )

                            }

                        }
                        catch(err){
                            console.log('err caught',err)
                        }


                    }
                    else{
                        if (existingMatch){
                            shouldRender = true
                        }
                    }
                }
                finally{
                    !shouldRender && navigate('/')
                }

            }
        )()

    },[])

}