import { Box,  Flex, Link,Image } from '@chakra-ui/react';
import Button from '../Widgets/Button';
import { MouseEvent, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom';
const NavBar = () => {

    const  navigate = useNavigate();

    let {origin,pathname} = window.location;


    let scrollIntoElement = useRef((target:string)=>{
            let element = document.getElementById(target);
            element?.scrollIntoView({ behavior: 'smooth' })
        }
    )

    const handleScroll = (e: MouseEvent<HTMLElement>, target: string) => {

        e.preventDefault();
        pathname.includes('match') ? navigate(`/?id=${target}`): scrollIntoElement.current(target);

    };

 
    useEffect(()=>{
        let path = window.location.href;
        
        let target = path.includes('?id=')? path.split('?id=')[1]:'';

        target && scrollIntoElement.current(target);

    },[])

    return (
        <>
            <Flex w="100%" opacity={1} px={{ base: '21px', lg: '120px' }} zIndex={50}>
                <Flex  justifyContent={'space-between'} py='1em' w="100%">
                    <Box alignItems={'center'} display={{ base: 'none', md: 'flex', }}>
                        <Link href={origin}>
                            <Image src='/images/WMTA.svg' alt='' width={'160px'} />
                        </Link>
                    </Box>
                    <Box display={{ base: 'flex', md: 'none' }} alignSelf={'center'}>
                        <img src='/images/WMTA.svg' alt='' width={'88px'} />
                    </Box>
                    <Flex justifyContent={'space-between'} gap={'100px'} alignItems={'center'}>
                        <Flex cursor={'pointer'} display={{ base: 'none', md: 'inline-flex' }} gap={'40px'}>
                            <span>
                                <Link href='' onClick={(e) => handleScroll(e, 'about')} fontSize={'18px'} textColor={'#000'} fontWeight={400}>
                                    About
                                </Link>
                            </span>
                            <span>
                                <Link href='' onClick={(e) => handleScroll(e, 'footer')} fontSize={'18px'} textColor={'#000'} fontWeight={400}>
                                    Contact Us
                                </Link>
                            </span>
                        </Flex>
                        <a href={`https://linktr.ee/whomadethisartwork`} id='get-involved' target='_blank'>
                            <Button cursor={'pointer'}
                                bg="#01172A" px={{ base: '14px', md: '44px' }} py={{ base: '10px', md: '15px' }} h={{ base: '35px', md: '50px' }} borderRadius={{ base: '6px', md: '12px' }}
                                textColor={'#FFF'} fontSize={{ base: '10px', md: '17px' }} fontWeight={{ base: '500', md: '600' }}>
                                Get Involved
                            </Button>
                        </a>
                    </Flex>
                </Flex>
            </Flex>
        </>
    )
}

export default NavBar;
