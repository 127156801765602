import {
    Box, Text, Flex, Input, SimpleGrid, Image, Stack, Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Center,
    Progress,
    Link
} from '@chakra-ui/react';

import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useEffect, useRef, useState } from 'react';
import UseArt from '../../hooks/useArt';
import Button from '../../components/Widgets/Button';
import { artistMatchType } from '../../App';
import useFiles from '../../hooks/useFiles';
import { useNavigate } from 'react-router-dom';



const Homepage = ({
    setArtistMatch
}: {
    setArtistMatch: Dispatch<SetStateAction<artistMatchType>>
}) => {


    const { fetchSampleImages, checkArt, fetchRecentImages, onFileDrop, onFileUpload } = UseArt();

    const navigate = useNavigate()

    const [data, setData] = useState([]);

    const [recentData, setRecentData] = useState([]);

    const [submitting, setSubmitting] = useState(false);

    const [loading, setLoading] = useState(false)

    const [error, setError] = useState('');

    const [visible, setVisible] = useState(false);



    const showPlaceholder = visible ? "hidden" : "visible";

    const { toast } = useFiles()
    console.log('should switch', error, setArtistMatch)

    const toggle = () => {
        let { image_url } = inputArt
        !image_url && setVisible(prev => prev ? false : true);
    }


    const [inputArt, setInputArt] = useState<{
        ip_address: string,
        image_url: string,
        image: File | string
    }>({
        'ip_address': '188.168.159.249',
        'image_url': '',
        image: ''
    });


    const handleArtInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputArt({ ...inputArt, [name]: value })
    }

    const fetchImagesRef = useRef({
        fetchRecentImages,
        fetchSampleImages
    })

    useEffect(() => {

        const fetchImages = async () => {
            try {
                const { fetchRecentImages, fetchSampleImages } = fetchImagesRef.current

                const [{ data: sampleData }, { data: RecentData }] = await Promise.all([fetchSampleImages(), fetchRecentImages()]);

                sampleData && setData(sampleData?.data);


                RecentData && setRecentData(RecentData?.data);

            } catch (error) {

                console.error('Error fetching data:', error);
                setError('An error occurred while fetching data.');
            }
        };
        fetchImages();

    }, []);



    const handleCheckArt = async (payload = inputArt) => {
        try {
            setSubmitting(true);

            await checkArt(payload, setArtistMatch);

            navigate('/match');


        }
        catch (err) {
            console.log('error fetching art meta', err)
        }
        finally {
            setSubmitting(false)
        }

    };


    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        let validatedFile = onFileUpload(e)

        if (validatedFile) {
            const payload = {
                ...inputArt,
                image: validatedFile
            }

            handleCheckArt(payload)

        }


    }

    const handleDrop = (e: React.DragEvent) => {

        let validatedFile = onFileDrop(e)
        if (validatedFile) {
            const payload = {
                ...inputArt,
                image: validatedFile
            }

            handleCheckArt(payload)

        }


    }
    // Simulate making an Axios request with folderData



    const searchImageOnClick = async (val: string) => {
        try {
            setLoading(true)
            let payload = {
                ...inputArt,
                image_url: val
            }

            handleCheckArt(payload);
        }
        finally {

            setLoading(false)

        }

    };

    const handleSubmitLink = (e?: FormEvent<HTMLFormElement>,) => {
        e?.preventDefault();
        if (!inputArt.image_url) {
            toast({
                status: 'error',
                title: 'Required',
                position: 'top',
                description: 'Please click on the icon to select an image'
            })
        }
        else {
            handleCheckArt()
        }
    }

    const uploadTips = [
        'Ensure the painting is the focus of the photo',
        'Upload the clearest version of the painting that you have',
        'Please upload just photos of paintings  for now. We can’t do video yet',
        'Ensure your image is less than 2MB',
        'Remember to give feedback on if you think the result was correct or not'
    ]







    return (
        <>
            {
                loading ?
                    <Progress w='full' size='md'
                        pos={'fixed'} top={0} left={0} colorScheme="var(--primary-color)" isIndeterminate /> :
                    <></>
            }

            <Flex flexDir={'column'} w={'100%'}>

                <Box position={'relative'}
                    bg={'linear-gradient(228deg, rgba(182, 46, 46, 0.12) 22.62%, rgba(191, 141, 42, 0.19) 61.61%, rgba(11, 82, 147, 0.09) 83.29%, rgba(147, 68, 11, 0.08) 109.34%)'}
                    w={'100%'} display={'flex'} flexDir="column" pb={'40px'} >

                    <NavBar />

                    <Box px={{ base: '16px', md: '32px', lg: '' }} display={'flex'} gap={'30px'} top={{ base: '122px', md: '218px' }} flexDir={'column'} alignItems={'center'} >
                        <Flex position={'relative'} flexDir={'column'} alignItems={'center'} gap={{ base: '14px', md: '31px' }}>
                            <Text maxW={{ base: '330px', lg: '967px' }} mt='0.5em' textAlign={'center'}
                                bg={'light.brand.1000'} backgroundClip={'text'} fill={'transparent'}
                                fontSize={{ base: '40px', md: '50px' }} fontWeight={700}>
                                Discover the Artist behind the Artwork
                            </Text>
                            {/*Mobile*/}
                            <Flex justify={{ base: 'flex-start', lg: 'center' }}>
                                <Text
                                    maxW={{ base: '320px', lg: '800px' }}
                                    display={{ base: 'inline-flex' }}
                                    textAlign={'center'} color={'light.brand.500'} fontSize={{ base: '14px', md: '16px', lg: '19px' }} fontWeight={400}>
                                    Discover the creators of interesting art pieces. Explore, identify, and appreciate African art with the help of AI
                                </Text>
                            </Flex>

                            <Image
                                position={'absolute'}
                                height={{ base: '20px', lg: '35px' }}
                                right={{ base: '20px', xl: '-100px', '2xl': '-130px' }}
                                top={{ base: '10px', xl: '60px' }}
                                src='/images/Blue-star.svg' alt=''
                            />
                            <Image src='/images/Red-star.svg'
                                position={'absolute'}
                                right={{ base: '-10px', lg: '20px', xl: '30px' }}
                                height={{ base: '20px', lg: '35px' }}
                                top={{ base: '30%', md: '150px', lg: '170px' }}
                                insetEnd={0}
                                alt='' />
                            {/* <Box visibility={{base:'visible', md:'hidden'}} position={'absolute'} insetEnd={0} insetBlockStart={5}>
                                <img src='/images/Blue-star.svg' alt='' width={'14px'}/>
                            </Box>
                            <Box visibility={{base:'visible', md:'hidden'}} position={'absolute'} insetInlineEnd={5} insetBlockEnd={78}>
                                <img src='/images/Red-star.svg' alt='' width={'14px'}/>
                            </Box> */}
                        </Flex>
                        <Box position={'absolute'} visibility={{ base: 'hidden', lg: 'visible' }}
                            insetBlockEnd={100} insetEnd={'0'}>
                            <Image
                                width={{ base: '200px', 'xl': '220px', '2xl': '260px' }}
                                src="images/Curved-arrow-vector.svg"
                                alt=""
                            />
                        </Box>
                        <Box bg={'#F7F6F6'} zIndex="10" w={{ base: '254px', md: '576px', 'xl': '602px' }} h={{ base: '265px', md: '387px', '2xl': '374px' }} display={'flex'} flexDir={'column'}
                            alignItems={'center'} border={'1px dashed #656363'} borderRadius={'20px'}
                            pt={{ base: '32px', md: '62px' }} px="12px" boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'}>
                            <Box position='relative'>
                                <Input type='file' w='100%' position='absolute'
                                    onDragOver={e => e.preventDefault()}
                                    onDrop={handleDrop}
                                    onChange={handleFileUpload}
                                    opacity={0} height='100' top={0}
                                    left={0} className='upload'
                                />
                                <img src='/images/add-image.svg' alt='' width={'100px'} />
                            </Box>
                            <Flex flexDir={'column'} alignItems='center' justifyContent='space-between' gap={'14px'}>
                                <Text cursor={'pointer'} textAlign="center" display={'inline-flex'} textColor="#1C1C1C" fontSize={{ base: '12px', md: '20px' }} fontWeight={500}>
                                    Click the icon above to upload a photo
                                </Text>
                                {/* <Text textColor="#545454" fontSize={{ base: '8px', md: '14px' }} fontWeight={400}>OR</Text> */}
                                <form onSubmit={handleSubmitLink} >
                                    {/* <Flex flexDir="column" justifyContent="space-between" gap={{ base: "8px", md: "22px" }}>
                                         <Flex position='relative' flexDir="column"
                                            bg={'#ECEBEB'} justify-content='center' alignItems='center'
                                            borderRadius={{ base: '6px', md: '12px' }} w={{ base: '154px', md: '396px' }}
                                            h={{ base: '24px', md: '45px' }} >
                                            <Input
                                                onChange={handleArtInput}
                                                onFocus={toggle}
                                                onBlur={toggle}
                                                type='text'
                                                border={'0'}
                                                name='image_url'
                                            />
                                            <Flex align='center' display={'inline-flex'}
                                                visibility={`${showPlaceholder}`} insetY='2'
                                                pointerEvents='none' gap={'2px'} position="absolute" textAlign="center" >
                                                <span>
                                                    <Text color='light.brand.1100' fontSize={{ base: '8px', md: '14px' }} fontWeight={300}>
                                                        Paste
                                                    </Text>
                                                </span>
                                                <span>
                                                    <Text color='light.brand.1100' fontSize={{ base: '8px', md: '14px' }} fontWeight={700}>
                                                        URL
                                                    </Text>
                                                </span>
                                            </Flex> 
                                    </Flex> */}
                                    <Button type='submit' isLoading={submitting} cursor={'pointer'} bg="#01172A" px={'20px'} w={{ base: '154px', md: '396px' }}
                                        h={{ base: '35px', md: '65px' }} borderRadius={{ base: '6px', md: '9px' }}
                                        textColor={'light.brand.10'} fontSize={{ base: '12px', md: '20px' }}
                                        fontWeight={{ base: '500', md: '600' }}>
                                        Predict
                                    </Button>
                                    {/* </Flex> */}
                                </form>

                            </Flex>
                            <Text fontSize={{ base: '10px', md: '14px' }} cursor={'pointer'}
                                onClick={() => document.getElementById('tips')?.scrollIntoView({ behavior: 'smooth' })}
                                mt='14px' textColor={'rgba(171, 46, 18, 0.71)'} fontWeight={500}>
                                How to get the best results
                            </Text>
                        </Box>

                        <Flex flexDir={{ base: 'column', md: 'row' }} alignItems={'center'} gap={'24px'} justifyContent={{ base: 'center', lg: 'space-between' }}>
                            <Box w={{ base: '192px', md: '170px' }}>
                                <Text display="inline-flex" textAlign={{ base: "center", md: "justify" }}
                                    fontSize={'14px'} textColor={'#1C1C1C'} fontWeight={400}>
                                    Don't have an image?
                                </Text>
                                <Text fontSize='14px'>
                                    Try out these images.
                                </Text>
                            </Box>
                            <Stack direction='row'>

                                {data?.map((item: any, index) => (
                                    <Image
                                        cursor={'pointer'}
                                        _hover={{
                                            border: '2px solid #545454'
                                        }}
                                        key={item?.id + index}
                                        boxSize='70px'
                                        borderRadius='6px'
                                        objectFit='cover'
                                        src={`${item?.photo}`}
                                        alt={`sample ${index}`}
                                        onClick={() => searchImageOnClick(item?.photo)}
                                    />
                                ))}

                            </Stack>
                        </Flex>
                        <Flex direction={'column'} alignItems={'center'} justifyContent={'space-between'} gap={'20px'}>
                            <Text display="inline-flex" textAlign={'center'}
                                fontSize={'14px'} textColor={'#1C1C1C'} fontWeight={400}>
                                By uploading an image or URL you agree to our <Link href={`https://fearless-fish-de9.notion.site/Terms-Conditions-d249e3342bb9420baeb138637602a413`} target='_blank' textColor={'#e56b36'}>terms & conditions</Link>
                            </Text>
                            {/* <Text fontSize='14px'>
                                        Try out these images.
                                    </Text> */}
                        </Flex>
                    </Box>

                </Box >

                <Box display={'flex'} flexDirection={'column'} gap={{ base: '90px', md: '120px' }} bg={'#FFF'} w={'100%'} py={'80px'} px={{ base: '24px', md: '40px', xl: '80px' }} alignItems={'center'}>
                    <Flex direction={'column'} alignItems={'center'} justifyContent={'space-between'}>
                        {/*Web*/}
                        <Text id='about' display={'inline-flex'} fontSize={{ base: '24px', lg: '40px', xl: '45px' }} fontWeight={700} textColor={'#1C1C1C'}>
                            Why does WMTA exist?
                        </Text>
                        <Text maxW={'628px'} fontSize={{ base: '12px', md: '18px' }} fontWeight={400} textColor={'#545454'} textAlign={'center'}>
                            As more people become interested in African art, it should be easier to find interesting artworks, artists and learn more about them. We want to figure out how to document the work of old African artists and make them digitally accessible. We also want to make it easier to discover and support new artists.
                        </Text>
                    </Flex>

                    <Flex direction={{ base: 'column', lg: 'row' }} alignItems={'center'}>
                        <Flex direction={'column'} gap={'8px'}>
                            <Text fontSize={'24px'} fontWeight={600} textAlign={'center'} textColor={'#1A202C'}>
                                Documentation
                            </Text>
                            <Text minW={{ base: '280px', lg: '260px' }} fontSize={'20px'} fontWeight={400} textAlign={'center'} textColor={'#545454'}>
                                We’re documenting artworks from African across the globe to ensure digital preservation
                                and accessibility.
                            </Text>
                        </Flex>
                        <Image
                            boxSize={{ base: '195', lg: '175px' }}
                            objectFit='cover'
                            src='images/Curve-line.svg'
                            alt=''
                            transform={{ base: 'rotate(8deg)', lg: 'initial' }}
                        />
                        <Flex direction={'column'} gap={'8px'}>
                            <Text fontSize={'24px'} fontWeight={600} textAlign={'center'} textColor={'#1A202C'}>
                                Identification
                            </Text>
                            <Text minW={{ base: '280px', lg: '260px' }} fontSize={'20px'} fontWeight={400} textAlign={'center'} textColor={'#545454'}>
                                We’re using Artificial Intelligence to help art lovers identify art works,
                                the artists who created them and stories behind them.
                            </Text>
                        </Flex>
                        <Image
                            boxSize={{ base: '195', lg: '175px' }}
                            objectFit='cover'
                            src='images/Curve-line.svg'
                            alt=''
                            transform={{ base: 'rotate(8deg)', lg: 'initial' }}
                        />
                        <Flex direction={'column'} gap={'8px'}>
                            <Text fontSize={'24px'} fontWeight={600} textAlign={'center'} textColor={'#1A202C'}>
                                Recognition
                            </Text>
                            <Text minW={{ base: '320px', lg: '260px' }} fontSize={'20px'} fontWeight={400} textAlign={'center'} textColor={'#545454'}>
                                We’re working to help established artists find new fans, while also spotlighting and recognising emerging talents.
                            </Text>
                        </Flex>
                    </Flex>

                    <Flex direction={{ base: 'column', lg: 'row' }} justifyContent={'space-between'} alignItems={'center'} w={'100%'} gap="30px">
                        <Text textAlign={{ base: "center", lg: "left" }}
                            w={{ base: '234px', lg: '400px' }} fontSize={{ base: '24px', lg: '45px' }} fontWeight={{ base: 700, md: 600 }} textColor={'#1C1C1C'}>
                            Where are we?
                        </Text>
                        <Flex justifyContent={'space-between'} alignItems={'center'} py={'36px'} px={'36px'} bg={'#A1C3E5'} gap={'120px'} >
                            <Flex direction={'column'} justifyContent={'space-between'} alignItems={'center'} >
                                <Text fontWeight={600} fontSize={{ base: '20px', md: '48px' }} textColor={'#181818'}>
                                    85%
                                </Text>
                                <Text fontWeight={500} fontSize={{ base: '14px', md: '21px' }} textColor={'#4C4B4B'}>
                                    Accuracy
                                </Text>
                            </Flex>
                            <Flex direction={'column'} justifyContent={'space-between'} alignItems={'center'} >
                                <Text fontWeight={600} fontSize={{ base: '20px', md: '48px' }} textColor={'#181818'}>
                                    100+
                                </Text>
                                <Text fontWeight={500} fontSize={{ base: '14px', md: '21x' }} textColor={'#4C4B4B'}>
                                    Artists
                                    Recognised
                                </Text>
                            </Flex>
                        </Flex>
                    </Flex>

                    {/* sponsors                                     */}
                    {/* <Flex direction={'column'} bg={'#EDEDED'} w={'100%'} pt={'10px'} pb={'20px'} px={'60px'} alignItems={'center'} justifyContent={'space-around'}>
                        <Flex alignItems={'center'} gap={2} flexWrap={'wrap'} >
                            <Image
                             width={{ base: '55px', lg: '143px' }}
                             objectFit='cover'
                             src='images/netflix.svg'
                             alt=''
                            />
                            <Image
                            //  transform={{ lg: 'rotate(5deg) scale(0.8)' }}
                             objectFit='cover'
                             src='images/axon.svg'
                             alt=''
                            />
                            <Image
                                width={{ base: '61px', lg: '218px' }}
                                // filter={'grayscale(100%)'}
                                src='images/expedia.svg'
                                alt=''
                            />
                            <Image
                                width={{ base: '52px', lg: '167px' }}
                                objectFit='cover'
                                src='images/tiktok.svg'
                                alt=''
                            />
                            <Image
                                width={{ base: '67px', lg: '114px' }}
                                objectFit='cover'
                                filter={'grayscale(100%)'}
                                src='images/alitia.svg'
                                alt=''

                            />
                            <Image
                                width={{ base: '62px', lg: '207px' }}
                                objectFit='cover'
                                src='images/jetstar.svg'
                                alt=''
                                filter={'grayscale(100%)'}
                            />
                            <Image
                                width={{ base: '76px', lg: '194px' }}
                                objectFit='cover'
                                filter={'grayscale(100%)'}
                                src='images/qantas.svg'
                                alt=''
                            />
                        </Flex>
                    </Flex> */}

                    <Flex direction={'column'} alignItems={'center'} justifyContent={'space-between'} gap={'20px'}>
                        {/*Web*/}
                        <Text display={'inline-flex'} fontSize={{ base: '24px', lg: '42px' }}
                            fontWeight={700} textColor={'#1C1C1C'} textAlign={'center'}>
                            Artworks others are exploring
                        </Text>
                        <Text maxW={'713px'} fontSize={{ base: '12px', md: '20px' }} fontWeight={400} textColor={'#545454'} textAlign={'center'}>
                            See what artists others have discovered. Here are some recently searched artworks
                        </Text>
                    </Flex>

                    <SimpleGrid w='full' columns={{ base: 2, md: 3, lg: 4 }} spacingY={14} spacingX={4} >

                        {recentData?.map((item: any, index) => (
                            <Box key={item.id + index} display={'flex'} bg={`url(${item?.photo})`} bgSize={'cover'} backgroundRepeat={'no-repeat'} borderRadius={'6px'}
                                objectFit={'cover'} w={{ base: 'full', sm: '170px', md: '200px', lg: '250px', "xl": '280px', '2xl': '340px' }} h={{ base: '200px', md: '388px' }} py={{ base: '13px', md: '15px' }} justifyContent={'center'} alignItems={'end'}>
                                <Button display={'flex'} cursor={'pointer'} bg={'#FFF'}
                                    p={'14px 30px'} borderRadius={'6px'} opacity={1}
                                    disabled={loading}
                                    fontSize={{ base: '12px', md: '16px' }} fontWeight={700} textColor={'#1C1C1C'}
                                    w={{ base: '68px', md: '161px' }} onClick={() => searchImageOnClick(item?.photo)}
                                >
                                    Explore
                                </Button>
                            </Box>
                        ))};

                    </SimpleGrid>

                    <Flex id='tips' direction={'column'} w='100%' gap={6}>
                        <Box>
                            <Text maxW={'570px'} textAlign={{ base: 'center', lg: 'left' }}
                                fontSize={{ base: '24px', md: '32px', }} fontWeight={700} textColor={'#1C1C1C'} >
                                Achieve the best results with these steps
                            </Text>
                        </Box>
                        <Flex direction={{ base: 'column-reverse', lg: 'row' }} justify={{ lg: 'space-between' }} alignItems={{ base: 'center', lg: 'start' }} gap={{ base: 6, lg: 12 }}>
                            <Flex direction={'column'} gap={'54px'} alignItems={'center'} py={'30px'} w={{ base: '100%', lg: '480px' }} bg={{ base: '#FFF', lg: '#FBFBFB' }} borderRadius={'6px'}>
                                <Text fontSize={'28px'} textColor={'#031D3A'} fontWeight={500}>
                                    Quick Tips
                                </Text>
                                <Box display={'flex'} flexDirection={'column'} gap={4} alignItems={'center'}>
                                    {
                                        uploadTips.map((tip, index) =>

                                            <Flex maxW={{ base: '300px', lg: '350px' }} key={index} alignItems={'center'} gap={4}>
                                                <Image
                                                    boxSize={{ base: '12px', md: '20px' }}
                                                    src='/images/Dot.svg'
                                                    alt=''
                                                />
                                                <Text fontWeight={400} textColor={'#000'} fontSize={{ base: '14px', md: '15px' }}>{tip}</Text>
                                            </Flex>

                                        )
                                    }
                                </Box>
                                <Button cursor={'pointer'} bg="#1A202C"
                                    px={'15px'} w={{ base: '80%', md: '275px' }}
                                    onClick={() => {
                                        document.getElementById('upload_image')?.scrollIntoView({ 'behavior': 'smooth' })
                                    }}
                                    h={{ base: '55px', md: '65px' }} borderRadius={{ base: '6px', md: '12px' }}
                                    textColor={'#FFF'} fontSize={{ base: '12px', md: '20px' }} fontWeight={{ base: '500', md: '600' }}>
                                    Upload Image
                                </Button>
                            </Flex>
                            <Image
                                width={{ base: '220px', md: '400px', lg: '450px' }}
                                src='images/Eclipse-2.svg'
                                alt=''
                            />
                        </Flex>
                    </Flex>

                    <Flex w='100%'>
                        <Flex direction={{ base: 'column', lg: 'row' }} flex={1} justifyContent={'space-between'} alignItems={{ base: 'center', lg: 'start' }} gap={8}>
                            <Image
                                width={{ base: '220px', md: '400px' }}
                                src='images/Eclipse-1.svg'
                                alt=''
                            />
                            <Flex direction={'column'} gap={6} w={{ base: '100%', lg: '500px' }} alignItems={{ base: 'center', lg: 'flex-start' }}>
                                <Text fontSize={{ base: '28px', lg: '32px' }} textColor={'#1C1C1C'} fontWeight={700}>
                                    Get Involved
                                </Text>
                                <Text textAlign={{ base: 'center', lg: 'left' }} maxW={'649px'} textColor={'#787777'} fontSize={{ base: '14px', md: '17px' }} fontWeight={400}>
                                    Join us in shaping the future of African art preservation and exploration.
                                    Are you an artist, collector, gallery, tech practitioner or just an art lover?
                                    Together, let's create a lasting legacy, ensuring the vibrant spirit of African art thrives for generations to come. Get involved and be a vital part of this journey!
                                </Text>
                                <Link w={{ base: '80%', md: '275px' }} href={`https://linktr.ee/whomadethisartwork`} target='_blank'>
                                    <Button cursor={'pointer'} mt={'20px'}
                                        bg="#1A202C" px={'15px'} w={{ base: 'full', md: '275px' }}
                                        h={{ base: '50px', md: '65px' }}
                                        borderRadius={{ base: '6px', md: '9px' }}
                                        id='getInvolved'
                                        textColor={'#FFF'} fontSize={{ base: '12px', md: '17px' }}
                                        fontWeight={{ base: '500', md: '600' }}>
                                        Get Involved today
                                    </Button>
                                </Link>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex id='faq' direction={'column'} w="100%" alignItems={'center'} justifyContent={'space-between'} gap={'53px'}>
                        <Text maxW={{ base: '260px', lg: '750px' }} textAlign='center' fontSize={{ base: '24px', md: '38px' }} fontWeight={700} textColor={'#1C1C1C'} >
                            Frequently Asked Questions
                        </Text>
                        <Accordion allowToggle display={'flex'} flexDirection={'column'} width={'100%'} justifyContent={'space-between'} gap={{ base: '20px', md: '80px' }} borderColor={'#FFF'}>
                            <AccordionItem width={'100%'}>
                                <h2>
                                    <AccordionButton justifyContent={'space-between'} py={'16px'} px={'24px'} border={'1px solid #E9E9E9'} borderRadius={'12px'}>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={500} fontSize={{ base: '16px', md: '24px' }}>
                                            How does the AI technology identify African artworks?
                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel py={'16px'} px={'24px'} textColor={'#545454'} fontSize={'18px'} fontWeight={400}>
                                    - Our AI model uses advanced image recognition algorithms trained on diverse datasets of artworks.
                                    It analyzes key features, styles, and contextual elements to accurately identify and catalog artworks                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem width={'100%'}>
                                <h2>
                                    <AccordionButton justifyContent={'space-between'} py={'16px'} px={'24px'} border={'1px solid #E9E9E9'} borderRadius={'12px'}>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={500} fontSize={{ base: '16px', md: '24px' }}>
                                            Can I contribute my own artworks to the platform?




                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel py={'16px'} px={'24px'} textColor={'#545454'} fontSize={'18px'} fontWeight={400}>
                                    - Absolutely! We encourage artists, collectors, and enthusiasts to contribute their artworks.
                                    Simply click on “Get Involved” and follow the guide to add your art pieces to the project and be part of the community.
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem width={'100%'}>
                                <h2>
                                    <AccordionButton justifyContent={'space-between'} py={'16px'} px={'24px'} border={'1px solid #E9E9E9'} borderRadius={'12px'}>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={500} fontSize={{ base: '16px', md: '24px' }}>
                                            What information is available about each identified artwork?                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel py={'16px'} px={'24px'} textColor={'#545454'} fontSize={'18px'} fontWeight={400}>
                                    - For each identified artwork, we provide information about the artist, medium, style,
                                    and any other relevant information that we have available.
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem width={'100%'}>
                                <h2>
                                    <AccordionButton justifyContent={'space-between'} py={'16px'} px={'24px'} border={'1px solid #E9E9E9'} borderRadius={'12px'}>
                                        <Box as="span" flex='1' textAlign='left' fontWeight={500} fontSize={{ base: '16px', md: '24px' }}>
                                            How do you ensure the accuracy of artist attributions?                                        </Box>
                                        <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel py={'16px'} px={'24px'} textColor={'#545454'} fontSize={'18px'} fontWeight={400}>
                                    - Our AI model is continuously trained on diverse datasets, and we leverage user feedback to refine and improve the identification process.
                                    We also cross-reference information from reputable sources to validate attributions and enhance accuracy.
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                    </Flex>
                </Box>

                <Footer />
            </Flex >
        </>
    )
};

export default Homepage;