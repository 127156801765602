import {Button as Btn,ButtonProps} from '@chakra-ui/react'

const Button = ({bg,color,children,isLoading,...rest}:ButtonProps)=>{


    return(
        <Btn bg={bg} color={color} _hover={{bg,color,}} isLoading={isLoading}
         {...rest} > 
            {children} 
        </Btn>
    )
}

export default Button;