import { Flex, Text, Box, Input, Button, SimpleGrid, useToast, Img } from "@chakra-ui/react"
import { FaEnvelope } from "react-icons/fa";
import { RiInstagramFill } from "react-icons/ri";
import { BsFillTelephoneFill, BsTwitterX } from "react-icons/bs";
import { FormEvent, MouseEvent, useRef, useState } from "react";
import UseAxios from "../../hooks/useAxios";
import { Link, useNavigate } from "react-router-dom";
const Footer = () => {


    const axios = UseAxios()

    const [submitting, setSubmitting] = useState(false);

    const toast = useToast();

    const navigate = useNavigate();

    let emailRef = useRef<HTMLInputElement | null>(null);

    const MAILCHIMP_SERVER = process.env['REACT_APP_MAILCHIMP_SERVER_PREFIX']

    const MAILCHIMP_LIST_ID = process.env['REACT_APP_MAILCHIMP_LIST_ID'];



    let scrollIntoElement = useRef((target: string) => {
        let element = document.getElementById(target);
        element?.scrollIntoView({ behavior: 'smooth' })
    }
    )

    let { pathname } = window.location;


    const handleScroll = (e: MouseEvent<HTMLElement>, target: string) => {

        e.preventDefault();
        pathname.includes('match') ? navigate(`/?id=${target}`) : scrollIntoElement.current(target);

    };



    const addToSubscription = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            setSubmitting(true);

            const email_address = emailRef?.current?.value;

            if (!email_address) {
                toast({
                    title: 'Required',
                    description: 'Email address is required',
                    position: 'top',
                    status: 'error',
                    isClosable: true
                })

            }

            else {


                let { data, status } = await axios.post(
                    `/join-mailing-list`,
                    {
                        email: email_address
                    },
                );


                let { message } = data as Record<string, any>;

                let description = message === 'Successful' ? 'You have been added to our mailing list' : ''

                toast({
                    status: 'success',
                    position: 'top',
                    title: 'Request successful',
                    description
                })

                // console.log('result',data,status);


            }


        }
        catch (err) {
            console.log('error in adding sub', err)


        }
        finally {
            setSubmitting(false);
        }

    }

    return (
        <Flex id='footer' fontFamily="Poppins, san-serif" bg="#1A202C" direction={'column'} pb="8px" pt={{ base: '31px', md: '45px' }} px={{ base: '16px', md: '80px', lg: '120px' }}
            justifyContent={'space-between'} alignItems={'center'} gap={4}>


            <Flex w="100%" justifyContent="space-between" direction={{ base: 'column', lg: 'row' }} gap={6}>
                <Box>
                    <Box display={{ lg: 'initial' }}>
                        <Img src='/images/WMTA-white.svg' alt='' width={{ base: '140px', lg: '170px' }} />
                    </Box>

                    <Text mt='0.8em' maxWidth={{ base: '370px', lg: '250px' }} display={'inline-flex'} fontWeight={500} fontSize={{ base: '16px', lg: '20px' }} textColor={'#FFF'}>
                        Documenting African Art and getting it the recognition it deserves
                    </Text>
                </Box>


                <Flex direction={'column'} gap={'14px'}>

                    <Flex minH={'66px'} alignItems={'center'} gap={'15px'}>
                        <FaEnvelope fontSize="25px" color="white" />
                        <Text fontWeight={400} className={'lato'} fontSize={'13px'} textColor={'#DEE5ED'}>
                            team@whomadethisartwork.com
                        </Text>
                    </Flex>
                    <Box px={{ lg: '1.2em' }}>
                        <Text fontWeight={600} fontSize={'20px'} textColor={'#FFF'}>
                            Explore
                        </Text>
                        <SimpleGrid mt='1em' columns={{ base: 3, md: 1 }} gap={3} alignItems={'left'}>
                            <Link to='/'>
                                <Text textColor={'#DEE5ED'} fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                    Home
                                </Text>
                            </Link>

                            <>
                                <Text onClick={(e) => handleScroll(e, 'about')} cursor={'pointer'} className={'lato'} textColor={'#DEE5ED'}
                                    fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                    About Us
                                </Text>
                            </>
                            <>
                                <Text onClick={() => emailRef.current?.focus()} className={'lato'} textColor={'#DEE5ED'} cursor={'pointer'} fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                    Contact Us
                                </Text>
                            </>
                            <a href={`https://linktr.ee/whomadethisartwork`} id='get-involved' target='_blank'>

                                <Text className={'lato'} textColor={'#DEE5ED'} cursor='pointer' fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                    Get Involved
                                </Text>
                            </a>
                            <>
                                <Text cursor='pointer' className={'lato'} textColor={'#DEE5ED'}
                                    onClick={(e) => handleScroll(e, 'faq')}
                                    fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                    FAQ
                                </Text>
                            </>
                        </SimpleGrid>
                    </Box>

                </Flex>

                <form onSubmit={addToSubscription}>
                    <Flex
                        maxW={'326px'} direction={'column'} gap={'16px'}>
                        <Flex minH={'66px'} alignItems={'center'} gap='12px'>
                            <a href='https://www.instagram.com/wmta_project/' target='_blank'>
                                <RiInstagramFill fontSize="25px" color="white" />
                            </a>
                            <a href='https://twitter.com/WMTA_project'
                                target='_blank'>
                                <BsTwitterX fontSize="22px" color="white" />
                            </a>
                        </Flex>
                        <Box>
                            <Text fontWeight={600} fontSize={'20px'} textColor={'#FFF'}>
                                Stay updated with WMTA
                            </Text>
                            <Text mt='0.5em' textColor={'#DEE5ED'} fontSize={'14px'} fontWeight={400}>
                                Join our mailing list to hear more about our plans
                            </Text>
                        </Box>

                        <Input ref={emailRef} display={'flex'} type="text" w="100%" h={{ base: "45px", md: "60px" }}
                            pl={'25px'} alignItems={'center'} placeholder="your email address" bg={'white'}
                            fontWeight='400' textColor='#B9B9B9' _placeholder={{ fontSize: '10px', md: '14px' }}

                        />

                        <Button cursor={'pointer'} bg="#E56B36" borderRadius={'6px'} w="100%"
                            h={{ base: "29px", md: "60px" }} p="17px 28px" textColor='#FFF'
                            fontWeight="500" fontSize="16px" isLoading={submitting} type='submit'
                        >
                            Subscribe
                        </Button>
                    </Flex>
                </form>

            </Flex>


            <Text textColor={'white'} fontSize={'16px'} fontWeight={400} fontFamily={'Lato'} mt={'20px'}>
                Made with ❤️ by Art lovers.
            </Text>

        </Flex>
    )
}

export default Footer;