import NavBar from "../../components/NavBar";
import Footer from "../../components/Footer";
import { Flex, Box, Image, Input, Text, Stack, VStack, Link, useDisclosure, ModalFooter, useToast, Progress } from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";
import { BiImageAdd } from "react-icons/bi";
import { RiShareFill } from "react-icons/ri";
import { ChangeEvent, Dispatch, FormEvent, SetStateAction, useState } from "react";
import Button from "../../components/Widgets/Button";
import { artistMatchType, existingMatch } from "../../App";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import SocialShareModal from "./SocialShareModal";
import { useIsMatched } from "../../hooks/usePermissions";
import UseArt from "../../hooks/useArt";
import { useSearchParams } from "react-router-dom";


const ArtistInfo = ({
    artistMatch,
    setArtistMatch
}: {
    artistMatch: artistMatchType,
    setArtistMatch: Dispatch<SetStateAction<artistMatchType>>
}) => {


    const [searchParams] = useSearchParams();


    const matchingId = searchParams.get('id') as string


    useIsMatched({ existingMatch: artistMatch, matchingId, setArtistMatch })

    let { request_id, artist } = artistMatch as existingMatch || {}

    const { isOpen, onOpen, onClose } = useDisclosure();

    const toast = useToast()

    const { checkArt, onFileDrop, onFileUpload, recordFeedBack } = UseArt()

    const [inputArt, setInputArt] = useState<{
        ip_address: string,
        image_url: string,
        image: File | string
    }>({
        'ip_address': '188.168.159.249',
        'image_url': '',
        image: ''
    });

    const [submitting, setSubmitting] = useState(false);

    const handleCheckArt = async (payload = inputArt) => {
        try {
            setSubmitting(true);

            await checkArt(payload, setArtistMatch);


        }
        catch (err) {
            console.log('error fetching art meta', err)
        }
        finally {
            setSubmitting(false)
        }

    };


    const handleArtInput = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e?.target;
        setInputArt({ ...inputArt, [name]: value })
    }



    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        let validatedFile = onFileUpload(e)

        console.log('validated', validatedFile)

        if (validatedFile) {
            const payload = {
                ...inputArt,
                image: validatedFile
            }

            handleCheckArt(payload)

        }


    }

    const handleDrop = (e: React.DragEvent) => {

        let validatedFile = onFileDrop(e)
        if (validatedFile) {
            const payload = {
                ...inputArt,
                image: validatedFile
            }

            handleCheckArt(payload)

        }


    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        handleCheckArt()
    }


    let handleSubmitFeedback = async (feedback: 'CORRECT' | 'WRONG') => {
        try {
            setSubmitting(true);

            let { data } = await recordFeedBack({
                request_id,
                feedback
            })


            console.log('feedback data', data)

            data?.status === 'Success' && toast({
                status: 'success',
                position: 'top',
                isClosable: true,
                description: `Feedback recorded. ${data?.message}`
            })


        }
        catch (err) {
            console.log('err in recording feedback', err)
        }
        finally {
            setSubmitting(false)
        }
    }


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Share this artwork</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <SocialShareModal request_id={request_id} />
                    </ModalBody>

                    <ModalFooter>
                        <Button bg='var(--primary-color)' color={'white'} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
            {
                submitting ?
                    <Progress w='full' size='md'
                        pos={'fixed'} top={0} left={0} colorScheme="var(--primary-color)" isIndeterminate /> :
                    <></>
            }

            <Flex flexDir={'column'} w={'100%'} bg={'white'}>
                <NavBar />
                <Box display={'Flex'} flexDir={'column'} w={'100%'} alignItems={'center'} gap={8} px={{ base: '16px', md: '60px', lg: '90px', xl: '120px' }} py={'40px'}>
                    <Flex w={'100%'} px={{ base: '16px', md: '60px' }}>
                        <Box border={''} h="36px" alignItems={'center'}
                            position='relative' w='full'
                            as='form'
                            onSubmit={handleFormSubmit}
                        >
                            <Flex position='absolute' top={0}
                                h={'full'} w='30px' display='flex' align='center'
                                justify={'space-between'} zIndex={10}
                                pt='0.4em' px='0.2em' pointerEvents='none'

                            >
                                <FiSearch color='C1CEDE' fontSize={'18px'} />
                            </Flex>

                            <Input display="flex"
                                onDrag={e => e.preventDefault()}
                                onDrop={handleDrop}
                                name='image_url'
                                onChange={handleArtInput}
                                borderRadius={'6px'} w='full' px='45px' bg={'#F9F7F7'}
                                type='text' placeholder='Upload an Image or paste a URL' alignItems={'center'}
                                disabled={submitting} _placeholder={{ fontSize: '10px', fontWeight: '500', textColor: '#C1CEDE' }}
                            />

                            <Input w='30px' h='30px' pos={'absolute'}
                                right={0} top={0} type='file'
                                opacity={0} className='upload'
                                zIndex={50}
                                onChange={handleFileUpload}
                            />

                            <Flex position='absolute' top={0}
                                h={'full'} w='30px' display='flex' align='center'
                                justify={'space-between'} zIndex={10} right={0}
                                pt='0.4em' px='0.3em' cursor='pointer'
                            >
                                <BiImageAdd color='C1CEDE' fontSize={'22px'} />
                            </Flex>

                        </Box>
                    </Flex>
                    <Text display={'inline-flex'} textAlign={'center'} fontSize={{ base: '10px', lg: '14px' }} textColor={'#303030'} fontWeight={500}>
                        Note: AI systems are not perfect and can sometimes give responses that are inaccurate. Visit the artist's website or social media accounts below to confirm.
                    </Text>

                    <Box display={'flex'} bg={{ base: '#FFF', lg: 'linear-gradient(228deg, rgba(182, 46, 46, 0.02) 22.62%, rgba(11, 82, 147, 0.03) 109.34%)' }}
                        w="100%" h={'fit-content'} flexDir={{ base: 'column', lg: 'row' }}
                        justifyContent={'space-between'} borderRadius={'12px'} py={'40px'} px={{ base: '', md: '22px' }}
                        gap={{ base: 6, lg: 9 }} alignItems={'start'}
                    >

                        <Image
                            display={'Flex'}
                            flex={{ base: 1, lg: 0.43 }}
                            w={'100%'}
                            h={"100%"}
                            src={artistMatch?.search_image}
                            alt={`${artistMatch?.artist.name}`}
                            maxW={{ lg: '400px', xl: '450px' }}
                        />
                        <Flex direction={'column'} alignItems={{ base: 'center', lg: 'flex-start', }} flex={{ base: 1, lg: 0.57 }}>
                            <Flex direction={{ base: 'column', lg: 'row' }} alignItems={{ lg: 'center' }} gap={{ base: '14px', lg: '2em' }}>
                                <Flex border={'3px solid linear-gradient(180deg, #EF3C03 0%, #1D1A46 55.73%)'}
                                    width={{ base: 'full', lg: '170px' }} justify={{ 'base': 'center', lg: 'initial' }}
                                    borderRadius="999px" h={{ base: '100px', md: '170px' }}>
                                    <Image
                                        w={{ base: '100px', md: '170px' }}
                                        borderRadius='50%'
                                        src={artistMatch?.artist.artist_photo}
                                        alt={`${artistMatch?.artist.name}`}
                                    />
                                </Flex>
                                <Flex direction={'column'} gap={1} alignItems={{ base: 'center', lg: 'unset' }} >
                                    <Flex minW={{ lg: '290px' }} alignItems={{ base: 'center' }} h={'max-content'} gap={1}>

                                        <Flex fontSize={{ base: "12px", md: "14px", lg: "14px" }} justifyContent={''} alignItems={''} >
                                            We're
                                        </Flex>
                                        <Text px='0.1em' display='inline'
                                            bg={'light.brand.1000'} backgroundClip={'text'} fill={'transparent'}

                                            fontSize={{ base: "28px", md: "22px" }} fontWeight="700">{artistMatch?.confidence}%</Text>
                                        <Flex fontSize={{ base: "12px", md: "14px", lg: "14px" }} justifyContent={''} alignItems={''} >
                                            sure that this artwork is by
                                        </Flex>
                                    </Flex>
                                    <Text display='inline'
                                        bg={'light.brand.1000'} backgroundClip={'text'} fill={'transparent'}
                                        fontSize={{ base: "28px", md: "24px" }} fontWeight="700">
                                        {artistMatch?.artist?.name}
                                    </Text>

                                </Flex>
                            </Flex>

                            <Flex direction={'column'} gap={6} alignItems={{ base: 'center', lg: 'flex-start' }}>
                                <Flex direction={{ base: 'column', lg: 'row' }} justify={{ lg: 'space-between' }}>
                                    <Flex mt='1em' direction={{ base: 'column', lg: 'row' }}
                                        align='center' gap={{ base: 1, lg: 4 }}>
                                        <Text display={'inline-flex'} textAlign={'left'} fontSize={{ base: '14px', lg: '17px' }} textColor={'#303030'} fontWeight={700}>
                                            Painting style:
                                        </Text>
                                        <Text textColor={'#909090'} fontSize={{ base: '12px', md: '18px' }} fontWeight={400}>
                                            {artistMatch?.art_details.painting_style}
                                        </Text>
                                    </Flex>
                                    <Flex mt='1em' direction={{ base: 'column', lg: 'row' }}
                                        align='center' gap={{ base: 1, lg: 4 }}>
                                        <Text display={'inline-flex'} textAlign={'left'}
                                            fontSize={{ base: '14px', lg: '18px' }} textColor={'#303030'} fontWeight={700}>
                                            Medium:
                                        </Text>
                                        <Text textColor={'#909090'} fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>{artistMatch?.art_details.medium}</Text>
                                    </Flex>
                                </Flex>

                                <Flex direction='column' alignItems={'left'} gap={1}>
                                    <Text mt='0.6em' display={'inline-flex'} textAlign={'left'} fontSize={{ base: '14px', lg: '18px' }} textColor={'#303030'} fontWeight={700}>
                                        Artist Bio
                                    </Text>
                                    <Text textAlign={'left'} textColor={'#909090'} fontSize={{ base: '12px', md: '16px' }} fontWeight={400}>
                                        {artistMatch?.artist.bio}
                                    </Text>
                                </Flex>
                                <VStack alignItems='flex-start' gap='1.2em' maxW='300px'>
                                    <Flex alignItems={'center'} gap={6}>
                                        <Link target='_blank' href={artist?.website}>
                                            <Button cursor={'pointer'} bg="#1A202C" p={{ base: '12px 32px', md: '14px 44px' }}
                                                w={{ base: '116px', md: '161px' }} h={{ base: '42px', md: '54px' }}
                                                borderRadius={{ base: '6px', md: '9px' }}
                                                textColor={'#FFF'} fontSize={{ base: '12px', md: '16x' }} fontWeight={'500'}>
                                                Website
                                            </Button>
                                        </Link>
                                        <Flex cursor={'pointer'} onClick={onOpen}>
                                            <RiShareFill fontSize="30px" color="black" />
                                        </Flex>
                                    </Flex>
                                    <Flex direction={'column'} gap={6} minW={{ base: 'full', lg: 'initial' }} alignItems={{ base: 'center', lg: 'inherit' }}>

                                        <Text display={{ base: 'flex', lg: 'none' }} textAlign={'center'} fontSize={'14px'} textColor={'14px'} fontWeight={500}>
                                            Connect with {artist?.name}
                                        </Text>
                                        <Stack direction='row' cursor={'pointer'}>
                                            {
                                                artist?.instagram ?
                                                    <Link target='_blank' href={artist?.instagram}>
                                                        <Image
                                                            boxSize='30px'
                                                            objectFit='cover'
                                                            src='/images/instagram.svg'
                                                            alt=''
                                                        />
                                                    </Link> : <></>
                                            }


                                            {
                                                artist?.twitter ?
                                                    <Link target='_blank' href={artist?.twitter}>
                                                        <Image
                                                            boxSize='30px'
                                                            objectFit='cover'
                                                            src='/images/twitter.svg'
                                                            alt=''
                                                        />
                                                    </Link> : <></>
                                            }
                                            {
                                                artist?.dribbble ?
                                                    <Link target='_blank' cursor='pointer' href={artist?.dribbble}>
                                                        <Image
                                                            boxSize='30px'
                                                            objectFit='cover'
                                                            src='/images/dribble.svg'
                                                            alt=''
                                                        />
                                                    </Link> : <></>

                                            }
                                            {
                                                artist?.behance ?
                                                    <Link target='_blank' cursor='pointer' href={artist?.behance}>
                                                        <Image
                                                            boxSize='30px'
                                                            objectFit='cover'
                                                            src='/images/behance.svg'
                                                            alt=''
                                                        />
                                                    </Link> : <></>
                                            }
                                            {
                                                artist?.tik_tok ?
                                                    <Link cursor='pointer' href={artist?.tik_tok} target={'_blank'}>
                                                        <Image
                                                            boxSize='30px'
                                                            objectFit='cover'
                                                            src='/images/tik_tok.svg'
                                                            alt=''
                                                        />
                                                    </Link> : <></>
                                            }
                                        </Stack>
                                    </Flex>


                                    <Flex direction={'column'} alignItems={{ base: 'center', lg: 'inherit' }} bg="rgba(255, 255, 255, 0.44)" boxShadow={'0px 4px 4px 0px rgba(0, 0, 0, 0.25)'} gap={2} borderRadius={'6px'} p={'12px 25px'}>
                                        <Text fontWeight={700} fontSize={'16px'} bgClip={'text'} textColor={'linear-gradient(85deg, rgba(139, 4, 4, 0.86) 14.75%, #1A202C 45.28%, #C76810 78.56%)'}>
                                            Does this look correct?
                                        </Text>
                                        <Stack direction='row' >
                                            <Image cursor={'pointer'}
                                                pointerEvents={submitting ? 'none' : 'initial'}
                                                boxSize='30px'
                                                objectFit='cover'
                                                src='/images/Thumb-up.svg'
                                                alt='upvote'
                                                onClick={() => handleSubmitFeedback('CORRECT')}
                                            />
                                            <Image
                                                pointerEvents={submitting ? 'none' : 'initial'}
                                                cursor={'pointer'}
                                                boxSize='30px'
                                                objectFit='cover'
                                                src='/images/Thumb-down.svg'
                                                alt='downvote'
                                                onClick={() => handleSubmitFeedback('WRONG')}
                                            />

                                        </Stack>
                                    </Flex>
                                </VStack>

                            </Flex>
                        </Flex>
                    </Box>
                </Box>
                <Footer />
            </Flex>
        </>
    )
}

export default ArtistInfo;
