import { useToast } from '@chakra-ui/react'

const useFiles = ()=>{
    
    const toast = useToast()
    
    const validateImage = ({
        size: fileSize,
        formats,
        file,
      }: {
        size?: number;
        formats?: string;
        file:File
      }) => {
        try{

        const IMG_FILE_SIZE = fileSize || 500000000;
    
        console.log('file',file)

        // application/x-zip-compressed
        const IMG_SUPPORTED_FORMATS =
          formats || "png, jpg, jpeg, image/png, image/jpg, image/jpeg";
    
        const { size, type } = file;
        let error = "";
        
            //validate file size
            if (size > IMG_FILE_SIZE) {
            error = "Image size is too large";
            } 

            //validate against unsupported formats
            else if (!IMG_SUPPORTED_FORMATS.includes(type)) {
            error = "Unsupported image format";
            }

         //validate agaist folder upload
    
            if (error) {
            toast({
                status: "error",
                title: "Error",
                description: error,
                position:'top'
            });
            }

    
            return !error;
        }
        catch(err:any){
            console.log('error in validation',err)
            
        }

    };

    return {
        validateImage,
        toast
    }
}

export default useFiles