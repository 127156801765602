import UseAxios from "./useAxios";
import { useToast } from '@chakra-ui/react'
import useFiles from "./useFiles";
import { ChangeEvent } from "react";

const UseArt = () => {

    const axios = UseAxios()

    const toast = useToast()

    const getIP = async () => {
        const pc = new RTCPeerConnection();
        const offer = await pc.createOffer();
        await pc.setLocalDescription(offer);
        const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3})/;
        const ipAddress = ipRegex?.exec(pc?.localDescription?.sdp as string);
        return ipAddress;
    }

    const { validateImage } = useFiles()


    const onFileDrop = (e: React.DragEvent) => {
        e.preventDefault();

        const files = e.dataTransfer.files;

        if (files.length > 1) {
            toast({
                status: 'error',
                position: 'top',
                description: 'You cannot upload multiple files at once'
            })
        }

        else {
            const file = files[0];
            let isValidated = validateImage({ file })

            if (isValidated){
                return file
            }
        }

    }

    
    const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {

        let files = e?.target?.files as FileList;

        let isValidated = validateImage({ file: files[0] })

        if(isValidated){
            return files[0]
        }
 
    }

    
    const checkArt = async (payload: Record<string, any>,set:(args?:any)=>any) => {
        const { image_url, image, ip_address } = payload
        console.log('init payload', payload)

        const ip_Address = getIP();

        console.log('ip address', ip_Address)

        try {
            let payloadObj = {
                ip_address,
                ...image ? {
                    image
                } : {
                    image_url
                }
            } as Record<string,any>

            let payload = new FormData();

            for (const key in payloadObj) {
                payload.append(key,payloadObj[key])
            }


            const response = await axios.post(
                `/check-art`,
                payload,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    }
                }
            );

            const { data } = response

            if (data?.status === 'Failed') {

                toast({
                    status: 'error',
                    title: 'Upload request failed',
                    description: data?.message
                })

                console.log('error res', data)
            }
            else {
                data?.status === 'Success' && set({
                    request_id:data?.request_id,
                    ...data?.data
                })

            }


        }
        catch (err: any) {
            console.log('new-axios-error', err?.message, err);
            err?.message &&
                toast({
                    status: 'error',
                    title: 'Check failed',
                    description: err?.message,
                    position: 'top',
                    isClosable: true,

                })
        }

    }


    const recordFeedBack =  (payload:{
        feedback:'CORRECT' | 'WRONG',
        request_id:string,
    })=>{

        let payloadAsFormData= new FormData();

        payloadAsFormData.append('feedback',payload['feedback']);

        payloadAsFormData.append('request_id',payload['request_id']);


        return(
            axios.post(`/record-user-feedback`,payload,{
                headers: {
                    "Content-Type": "multipart/form-data",
                }
            }
)
        )
    }


    const fetchSampleImages = async () => {
        const response = await axios.get('/sample-images');
        return response
    };


    const fetchRecentImages = async () => {
        const response = await axios.get('/recently-searched');
        return response;
    };



    return {
        checkArt,
        fetchSampleImages,
        fetchRecentImages,
        onFileDrop,
        onFileUpload,
        recordFeedBack
    }
}


export default UseArt