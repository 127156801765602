import { Box, Center, Circle, Flex, Input, InputGroup, InputRightElement, Text,useToast } from "@chakra-ui/react"
import { TwitterShareButton,TwitterIcon, FacebookShareButton, FacebookIcon,RedditShareButton,RedditIcon, WhatsappShareButton,WhatsappIcon } from 'react-share'

import { BiCopy } from "react-icons/bi"

const SocialShareModal =({request_id}:{request_id:string,})=>{
    
    // const link = 

    const toast = useToast()


    const feUrl = window.location.origin + `/match?id=${request_id}`
    
    const copy = ()=>{
        navigator.clipboard.writeText(feUrl as string);
        
        toast({
            title:'Link copied successfully',
            isClosable:true,
            status:'success',
            position:'top'
            
        })

    }

    const message = 'Checkout this artwork I found on whomadethisartwork.com'

    return(
        
        <>
            <Text mt='0.1em' fontSize={'14px'} color={'var(--greyDark)'}>Share this artwork to your socials</Text>

            <Flex mt='1em' justify={'space-between'}>

                <Box>

                    <Circle size={'60px'}>
                        <TwitterShareButton 
                         url= {feUrl}
                         title={message}
                        >
                            <TwitterIcon size={'50px'} round />
                        </TwitterShareButton>
                    </Circle>

                    <Center fontSize={'13px'} color={'var(--greyDark)'}>Twitter</Center>
                </Box>

                <Box>

                    <Circle size={'60px'}>
                        <FacebookShareButton 
                         url= {feUrl}
                        
                         
                        >
                            <FacebookIcon size={'50px'} round />
                        </FacebookShareButton>
                    </Circle>

                    <Center fontSize={'13px'} color={'var(--greyDark)'}>Facebook</Center>

                </Box>

                <Box>

                    <Circle size={'60px'}>
                        <RedditShareButton 
                         url= {feUrl}
                         title={message}
                        >
                            <RedditIcon size={'50px'} round />
                        </RedditShareButton>
                    </Circle>

                    <Center fontSize={'13px'} color={'var(--greyDark)'}>Reddit</Center>

                </Box>


                <Box>

                    <Circle size={'60px'}>
                        <WhatsappShareButton 
                         url= {feUrl}
                         title={message}
                        >
                            <WhatsappIcon size={'50px'} round />
                        </WhatsappShareButton>
                    </Circle>

                    <Center fontSize={'13px'} color={'var(--greyDark)'}>Whatsapp</Center>
                    
                </Box>


            </Flex>

            <InputGroup  mt='0.6em'>
                <Input h='35px' fontSize={'13px'} border='1px solid var(--greyDark)' 
                 disabled={true} value={feUrl }/>

                <InputRightElement>
                    <BiCopy cursor={'pointer'} onClick={()=>copy()} />
                </InputRightElement>
            </InputGroup>

        
        </>
        
    )
}

export default SocialShareModal